
.overvolume {
    .MuiSlider-track {
        color: red;
    }
}

.MuiSlider-root[value^="1."] {
    .MuiSlider-track {
        background-color: red;
    }
}