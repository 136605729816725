$tablespace: 12px;

.exitFullscreenBtn {
    position: absolute !important;
    top: 10px;
    right: 10px;
}

table {
    background-color: transparent;
    border: none;

    tr {
        background-color: transparent;
        border: none;
    }

    td {
        border: none !important;
        position: relative;
    }
    td:after {
        content: '';
        display: block;
        margin-top: 100%;
    }
    td[rowspan="2"]:after {
        content: '';
        display: block;
        margin-top: 200%;
    }

    td {
        .cellbox {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
    
            padding: 1em;
        }
    
        .cellbtn {
            width: 100%;
            height: 100%;
        }

        &.blankcell {
            background:red;
        }
    }
}

.remote {
    .fader {
        .MuiSlider-rail, .MuiSlider-track {
            width: 20px;
        }
        .MuiSlider-thumb {
            width: 40px;
            height: 80px;
            border-radius: 0;
            background-image: url("../../public/fader_handle_noshadow.png");
            background-position: center;
            background-repeat: no-repeat;
            background-color:transparent;
            background-size: contain;
            
        }
    }
}