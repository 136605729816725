
.host {
    .modal {
        position: 'absolute' as 'absolute';
        top: '50%';
        left: '50%';
        transform: 'translate(-50%, -50%)';
        width: 400;
        background-color: 'background.paper';
        border: '2px solid #000';
        box-shadow: 24;
        p: 4;
    }
}

.liveBtn {
    position: fixed !important;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    z-index: 10;
}


.groupsettings {
    display:flex;
    
    .MuiTextField-root {
        width: 100%;
        margin-right: 10px;
    }
    .MuiButton-Root {
        width: 4em;
    }
}

.addtrackbuttoncontainer {
    width: 100%;
    display: flex;

    button {
        margin: auto !important;
    }
}