
.dragHere:before{
    content: '';

    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    opacity: 0.2;
    background-color: black;

}
.dragHere:after{
    content: '';

    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;

    opacity: 0.2;
    border-color:inherit;
    border: 5px dashed;
    border-radius: 7px;

}

.dragNotif {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    border-color:inherit;
    border-radius: 7px;

    &.dragHere {
        opacity: 0.3;
        background-color: black;
        &:after {
            border-color:inherit;
            opacity: inherit;
            margin: 5px;
        }
        &:hover {
            opacity: 0.5;
        }
    }
    &:not(.dragHere) {
        display:none;
    }
} 