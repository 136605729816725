

.freesound {
    .search {
        display: flex;
        position: relative;
    }

    .resultlist {
        display:flex;
        align-items: center;
    }
    .spinner {
        margin: 10px auto;
    }

    .spinner {
        width: 100%;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    
    hr {
        margin-top: 10px;
    }
    .searchinstructions {
        font-size: 70%;
        text-align: center;
        margin-bottom: 10px;
    }
    .noresults {
        margin-top: 10px;
        text-align: center;
    }

    .resultlist {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.freesoundResult {
    padding: 0;
    margin: 10px 0;
    position: relative;
    &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        content: '';
        background-size: 100% 50%;
        background-repeat: no-repeat;
        background-position-y: 80%;
        opacity: 0.5;
    }

    hr {
        margin-top: 10px;
        position: relative;
    }
    h4 {
        line-height: 0;
        position: relative;
    }
    .author {
        font-size: 80%;
        text-shadow: 0px 0px 2px #FF000000;
        position: relative;
    }
    .gotoLink {
        float:right;
        position: relative;
    }
}