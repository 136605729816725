
.track {
  display:block;
  width: 100%;
  position: relative;

  .fader {
    display:inline-block;
    width: 100%;
  }
  .pitchslider {
    width: 20%;
    margin-right: 1em;
  }
  .mainsection {
    padding: 0 10px;
  }
  .namefield {
    width: 100%;
  }

  .namesection {
    width: 50%;
    button {
      width: 2.5em;
      min-width: 2.5em;
      height: 100%;
    }
  }
  .fadersection {
    width: 100%
  }
  .buttonsection {
    width: 9.2em;
    min-width: 9.2em;

    .MuiButtonBase-root {
      width: 3em;
      min-width: 3em;
      height: 3em;
    }
  }
  .controlButton {
    width: 3em;
    min-width: 3em;
    height: 3em;
  }

  .configSection {
    position: relative;

    .MuiBox-root {
      margin: 20px;
      padding: 20px;
    }
    .configButtons {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .volumeScalar {
      min-width: 10em;
    }

    .filelist {
      padding: 20px;

      .tip {
        font-size: 50%;
        margin-bottom: 1em;
      }
      
      .fileentry {
        position:relative;
        min-height: 40px;
        margin-bottom: 0.5em;
        border-radius: 5px;
        background-color: rgba(255,255,255,0.05);
        .filename {
          width: 100%;
          margin-right: 10px;
          padding: 0 5px;
          border-radius: 0 0 5px 5px;
          div > input {
            font-size: 60%;
          }
        }
        .filedetails {
          display:flex;
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 4;
          background-color: rgba(0,0,0,0.3);
          border-radius: 0 0 5px 5px;
          padding: 5px;
          button {
            z-index: 4;
            font-size: 50%;
          }
        }

        wave {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 80px;
          border-radius: 0 0 5px 5px;
        }
      }
      .newfile {
        margin-top: 10px;
        display:flex;
        .MuiButtonBase-root {
          margin-left: 10px;
        }
        .newfileinput {
          width:100%;
          padding-right: 10px;
          .MuiTextField-root {
            width: 100%;
          }
        }
      }
    }
  }
}